import React, { useEffect, useState } from 'react';

import camImg from "../assets/img/illustrations/cam.jpg"
import nicoImg from "../assets/img/illustrations/nico.jpg"
import gaelleImg from "../assets/img/illustrations/gaelle.jpg"
import alexisImg from "../assets/img/illustrations/alexis.jpg"
import eloImg from "../assets/img/illustrations/elo.jpg"
import marcImg from "../assets/img/illustrations/marc.jpg"

import locIco from "../assets/img/pictos/location-dot-solid.svg"

const Team = () => {
    const [offset, setOffset] = useState(0);
    const [illuDisplay, setIlluDisplay] = useState(false);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);

        if (offset > 1200) {
            setIlluDisplay(true);
            const illustrations = document.querySelectorAll(".drawing");
            for (let i = 0; i < illustrations.length; i++) {
                illustrations[i].style.animationDelay = `${600 + 300 * i}ms`;
            }
        }

        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, [offset]);

    return (
        <section className="team-section">
            <div className="illustration-part">
                <h3>Notre équipe</h3>
                <div className="team-container">
                    <div className="row">
                        <div className="illustration">
                            <div className="img-container">
                                {illuDisplay && <img className='drawing' src={camImg} alt="Camille, Développement commercial, marketing & communication." />}
                                <h4>Camille</h4>
                                <p>Développement commercial, marketing & communication</p>
                                <p className="localisation"><span>
                                    <img className='illustration' src={locIco} alt="Icone de localisation" />
                                    Marseille
                                </span></p>
                            </div>
                        </div>
                        <div className="illustration">
                            <div className="img-container">
                                {illuDisplay && <img className='drawing' src={gaelleImg} alt="Gaëlle, Communication visuelle." />}
                                <h4>GAËLLE</h4>
                                <p>Communication visuelle,<br /> graphisme</p>
                                <p className="localisation"><span>
                                    <img src={locIco} alt="Icone de localisation" />
                                    Paris
                                </span></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="illustration">
                            <div className="img-container">
                                {illuDisplay && <img className='drawing' src={marcImg} alt="Photographie & video." />}
                                <h4>Marc</h4>
                                <p>Photographie <br />& video</p>
                                <p className="localisation"><span>
                                    <img src={locIco} alt="Icone de localisation" />
                                    Aix-en-Provence
                                </span></p>
                            </div>
                        </div>
                        <div className="illustration">
                            <div className="img-container">
                                {illuDisplay && <img className='drawing' src={nicoImg} alt="Nicolas, Social Media & Web Marketing." />}

                                <h4>Nicolas</h4>
                                <p>Social Media <br />& Web Marketing</p>
                                <p className="localisation"><span>
                                    <img src={locIco} alt="Icone de localisation" />
                                    Paris
                                </span></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="illustration">
                            <div className="img-container">
                                {illuDisplay && <img className='drawing' src={eloImg} alt="Relation client." />}

                                <h4>Elodie</h4>
                                <p>Relation client</p>
                                <p className="localisation"><span>
                                    <img src={locIco} alt="Icone de localisation" />
                                    Paris
                                </span></p>
                            </div>
                        </div>
                        <div className="illustration">
                            <div className="img-container">
                                {illuDisplay && <img className='drawing' src={alexisImg} alt="Alexis, Développement Web." />}
                                <h4>Alexis</h4>
                                <p>Développement Web</p>
                                <p className="localisation"><span>
                                    <img src={locIco} alt="Icone de localisation" />
                                    Paris
                                </span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-part">
                <h5>Laissez-nous vous présenter l'équipe commerciale !</h5>
                <p>Chacun de nous possède son domaine d'expertise, et est installé dans une région viticole ou autour de la capitale.<br /><br />
                    Cette organisation, basée sur la complémentarité, nous permet d'avoir un savoir-faire complet en terme d'activité et de rayonner dans toutes les régions de France.</p>

                <h5>Notre force ? Nous sommes des professionnels du vin au service des métiers du vin.</h5>
                <p>Avant de nous regrouper dans l'Agence, nous exercions différentes activités dans le commerce du vin (au sein de domaines, enseigne de caviste, en sommellerie...). Ces expériences nous permettent d'avoir une vision claire des problématiques de nos clients aujourd'hui.</p>

                <div className="cta-container">
                    <button className="cta">
                        <a href="./portfolio.pdf" target="_blank" rel="noopener noreferrer">Découvrir notre portfolio</a>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Team;